<template>
  <div class="clinician-layout">
    <ModalWrapper />
    <MyAccountClinician />
    <ClinicianNavbar :user="user" />
    <ClinicianSidebar />
    <div class="clinician-content content">
      <div
        class="main-view"
        :class="{'clinician-welcome': isClinicianWelcomePage}"
      >
        <router-view />
      </div>
      <div class="detail-view" />
    </div>
  </div>
</template>

<script>
import ClinicianNavbar from '@/components/layout/ClinicianNavbar'
import ClinicianSidebar from '@/components/layout/ClinicianSidebar'
import ModalWrapper from '@/components/modal/modalWrapper'
import MyAccountClinician from '@/components/clinician/MyAccountClinician'
import { mapGetters } from 'vuex'

export default {
  name: 'ClinicianHomeView',
  components: {
    ClinicianNavbar,
    ClinicianSidebar,
    ModalWrapper,
    MyAccountClinician
  },
  computed: {
    ...mapGetters({
      user: 'loggedInUser'
    }),
    isClinicianWelcomePage () {
      return this.$route.name === 'WelcomeClinician'
    }
  },
  beforeCreate () {
    document.body.className = 'body-clinician-layout'
  }
}
</script>
