<template>
  <div
    class="clinician-sidebar side-bar"
    :class="{ isOpen: sidebarOpen }"
  >
    <QuickAddMenu />
    <ul
      class="list-unstyled"
      :class="isPatientView"
    >
      <router-link
        v-for="(item, index) in menuFiltered"
        v-show="item.show()"
        :key="index"
        :to="getMenuLink(item)"
        tag="li"
        :class="getActiveClass(item.href)"
        @click.native="HIDE_SIDEBAR"
      >
        <a
          v-tooltip="getTooltip(item)"
          :class="item.customClass"
        >
          <i :class="item.icon" />
          <span
            v-if="item.showBadge && item.badgeText"
            :class="{alert: item.badgeText}"
            class="badge"
          >{{ item.badgeText }}</span>
          <span
            v-if="item.showIconFix"
            class="icon-fix first badge"
          />
          <span
            v-if="item.showIconFix"
            class="icon-fix second badge"
          />
          <span class="text hiden-when-closed">{{ item.text }}</span>
        </a>
      </router-link>
    </ul>
  </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'
import QuickAddMenu from '@/components/clinician/QuickAddMenu'

export default {
  name: 'ClinicianSidebar',
  components: {
    QuickAddMenu
  },
  mixins: [Helpers, UserManagementComponentMixin],
  data () {
    return {
      menu: [{
        text: 'Home',
        icon: 'fal fa-home-alt',
        href: 'ClinicianHomepage',
        show: () => this.showHomeIcon,
        hasAccess: this.loggedUserIsClinicianOrStaff()
      },
      {
        text: 'Alerts',
        icon: 'fal fa-bell',
        href: 'ClinicianAlertList',
        tooltip: 'My alerts',
        show: () => true,
        showBadge: true,
        badgeText: 0,
        hasAccess: this.userHasAccessToAlertsList()
      },
      {
        text: 'Measures',
        icon: 'far fa-chart-line',
        href: 'MeasuresClinician',
        show: () => true,
        showIconFix: true,
        hasAccess: this.loggedUserIsStaffOrClinicianOrSupervisor()
      },
      {
        text: 'Patients',
        icon: 'fal fa-user',
        href: 'ClinicianPatientsList',
        show: () => this.showPatientListIcon,
        customClass: 'divisor',
        hasAccess: this.loggedUserIsStaffOrClinicianOrSupervisor()
      // },
      // {
      //   text: 'Programs',
      //   icon: 'fal fa-hands-helping',
      //   href: 'ClinicianPrograms',
      //   show: () => false,
      //   hasAccess: this.loggedUserIsAdmin()
      }, {
        text: 'Analytics',
        icon: 'fal fa-analytics',
        href: 'AnalyticsLayout',
        show: () => true,
        hasAccess: this.loggedUserIsDirector() || this.loggedUserIsAdmin()
      }, {
        text: 'Settings',
        icon: 'fal fa-cog',
        href: 'SettingsLayout',
        show: () => true,
        hasAccess: this.loggedUserIsAdmin() || this.loggedUserIsStaff()
      }]
    }
  },
  computed: {
    ...mapGetters({
      sidebarOpen: 'getSidebarIsOpen',
      alertsCount: 'getAlertsCount',
      assignments: 'getAssignments',
      userLogged: 'loggedInUser',
      activeAssignment: 'getActiveAssignment',
      settings: 'generalSettings'
    }),
    showHomeIcon () {
      return this.userHasAccessToHomePage()
    },
    showPatientListIcon () {
      return this.userHasAccessToReadPatientsList()
    },
    isPatientView () {
      return this.$route.matched.some(r => r.name === 'PatientLayout')
    },
    menuFiltered () {
      return this.menu.filter(item => {
        return item.hasAccess === true
      })
    }
  },
  watch: {
    alertsCount () {
      this.shouldShowAlertsBadge()
    }
  },
  created () {
    if (this.userHasAccessToAlertsList()) {
      this.$store.dispatch('GET_CLINICIAN_ALERTS_COUNT').then(() => {
        this.shouldShowAlertsBadge()
      }).catch((e) => {
        this.$handleApiError(e)
      })
    }
  },
  methods: {
    ...mapActions([
      'HIDE_SIDEBAR'
    ]),
    getActiveClass (href) {
      const inRespondentList = href === 'ClinicianPatientsList' && this.$route.name === 'ClinicianRespondentsList'
      if (inRespondentList) {
        return { active: true }
      }

      return {}
    },
    shouldShowAlertsBadge () {
      const item = this.menu.find(mi => mi.href === 'ClinicianAlertList')
      const alertsCount = this.alertsCount
      item.badgeText = alertsCount
      item.icon = alertsCount ? 'fas fa-bell with-alerts' : 'fal fa-bell'
    },
    getTooltip (item) {
      return this.sidebarOpen ? null : { content: item.tooltip ? item.tooltip : item.text, placement: 'right', classes: ['no-arrow', 'sidebar-tooltip'], delay: { show: 300, hide: 200 } }
    },
    getMenuLink (item) {
      if (item.href === 'ClinicianHomepage' && this.$route.query && this.$route.query.homeTab) {
        return { name: item.href, query: { homeTab: this.$route.query.homeTab } }
      }
      return { name: item.href }
    }
  }
}
</script>
