<template>
  <div
    v-if="items.length"
    class="dropdown button-add list-unstyled"
    :class="{show: plusOpen}"
    @mouseout="SET_SIDEBAR_ADD_STATUS"
  >
    <i
      class="fal fa-plus-circle button-add-target"
      @mouseover="SET_SIDEBAR_ADD_STATUS"
      @click.stop="SET_SIDEBAR_ADD_STATUS"
    />
    <div
      class="dropdown-menu contextual-dropdown"
      :class="{show: plusOpen}"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
      >
        <router-link
          v-if="item.routeName"
          :to="getLink(item)"
          class="dropdown-item"
          :class="item.class"
          :title="item.title"
        >
          {{ item.label }}
        </router-link>

        <a
          v-if="item.action"
          href="javascript:void(0)"
          class="dropdown-item"
          @click="executeAction(item.action, item.params)"
        >
          {{ $t('removeRespondent') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import { RespondentHelper } from '@/mixins/RespondentHelper'

import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'
import {
  SS_PROGRAMS,
  SS_APPOINTMENT_OPTIONS,
  SS_PROGRAM_OPTIONS
} from '@/data/setttingStates'

export default {
  name: 'ClinicianSidebar',
  mixins: [Helpers, RespondentHelper, UserManagementComponentMixin],
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters({
      plusOpen: 'getSidebarAddIsOpen',
      assignments: 'getAssignments',
      activeAssignment: 'getActiveAssignment',
      settings: 'generalSettings',
      patientProgramsAssignation: 'getSinglePatientPrograms',
      singlePatient: 'getSinglePatient',
      loadingGetPatient: 'getLoadingGetPatient',
      clinicianAssignments: 'getClinicianAssignments'
    }),
    items () {
      return this.isPatientView ? this.getPatientQuickMenu() : this.getMainQuickMenu()
    },
    programsEnabled () {
      return this.$store.getters.generalSettings.programs_enabled
    },
    isPatient () {
      return this.activeAssignment ? this.activeAssignment.patient_relation === 'Patient' : false
    },
    isPartOfTeam () {
      return this.activeAssignment ? !this.activeAssignment.deleted : false
    },
    isPatientView () {
      return this.$route.matched.some(r => r.name === 'PatientLayout')
    },
    patientId () {
      return this.isPatientView ? this.$getEncodedId(this.activeAssignment.user_id) : null
    },
    includeAppointmentsFeature () {
      return SS_APPOINTMENT_OPTIONS.includes(this.$store.getters.generalSettings.setting_state)
    },
    includeProgramsFeature () {
      return SS_PROGRAM_OPTIONS.includes(this.$store.getters.generalSettings.setting_state)
    },
    addPatient () {
      // Both program and appointments are enabled.
      if (this.programsEnabled && this.includeAppointmentsFeature) {
        return { routeName: 'AddPatientChooseProgram', label: this.$t('addPatient') }
      }

      // Only Program is enabled
      if (this.includeProgramsFeature) {
        return { routeName: 'ClinicianAddPatientByProgram', label: this.$t('addPatientToProgram') }
      }

      // No programs or only appointment are enabled.
      return { routeName: 'ClinicianAddPatient', label: this.$t('addPatient') }
    },
    showAddRespondent () {
      if (!this.isPatient) {
        return false
      }
      const programsAssignation = this.patientProgramsAssignation.filter(item => Number(item.discharged) === 0)
      return this.settings.setting_state === SS_PROGRAMS ? programsAssignation.length > 0 : true
    }
  },
  watch: {
    clinicianAssignments: {
      deep: true,
      handler (newVal) {
        this.getPatientQuickMenu(newVal)
      }
    }
  },
  created () {
    return this.isPatientView ? this.getClinicianCount() : null
  },
  methods: {
    ...mapActions([
      'SET_SIDEBAR_ADD_STATUS'
    ]),
    getMainQuickMenu () {
      const quickMenu = []
      if (this.userHasAccessToAddPatient()) {
        quickMenu.push({
          label: this.addPatient.label,
          routeName: this.addPatient.routeName
        })
      }
      return quickMenu
    },
    getPatientQuickMenu () {
      const quickMenu = []
      if (this.programsEnabled && this.isPatient && this.userHasAccessToAddToProgram()) {
        quickMenu.push({
          label: this.$t('addToProgram'),
          routeName: 'ClinicianAddPatientToProgram',
          params: {
            patientId: this.patientId
          }
        })
      }
      // check for clinician here  - TODO
      if (this.isPartOfTeam && this.showAddMeasure && this.userHasAccessToAddMeasure() && !this.loadingGetPatient && this.clinicianAssignments) {
        quickMenu.push({
          label: this.$t('addMeasure'),
          routeName: 'ClinicianPatientAddMeasure',
          params: {
            ccauId: this.$route.params.ccauId,
            ccaId: this.$route.params.ccaId
          }
        })
      } else if (this.isPartOfTeam && this.showAddMeasure && this.userHasAccessToAddMeasure() && !this.loadingGetPatient && !this.clinicianAssignments) {
        quickMenu.push({
          label: this.$t('addMeasure'),
          routeName: 'ClinicianPatientAddMeasure',
          params: {
            ccauId: this.$route.params.ccauId,
            ccaId: this.$route.params.ccaId
          },
          class: 'inactive',
          title: this.$t('noCliniciansAssigned')
        })
      }
      if (this.isPartOfTeam && !this.isPatient && this.userHasAccessToRemoveRespondent()) {
        quickMenu.push({
          label: this.$t('removeRespondent'),
          action: 'removeRespondent',
          params: this.$getDecodedId(this.$route.params.ccauId)
        })
      }
      if (this.showAddRespondent && this.userHasAccessToAddRespondent()) {
        quickMenu.push({
          label: this.$t('addRespondent').toLowerCase(),
          routeName: 'ClinicianPatientAddRespondent',
          params: {
            ccauId: this.$route.params.ccauId,
            ccaId: this.$route.params.ccaId
          }
        })
      }
      return quickMenu
    },
    executeAction (action, params) {
      if (typeof this[action] === 'function') {
        this[action](params)
      }
    },
    removeRespondent (id) {
      this.$removeRespondent(id).then((hasMeasures) => {
        this.$toast.success({ message: this.$t('respondentSumbit', { action: this.$t('removedFromTeamTag') }) })
        this.$store.dispatch('GET_PATIENTS_MEASURES', id)
        if (!hasMeasures) {
          // If it doesn't have measures respondent was deleted so we redirect to patient tab
          const patient = this.assignments.find(a => a.patient_relation_id === this.$getRelationIdPatient())
          this.$router.push({ name: 'ClinicianPatient', params: { ccaId: this.$getEncodedId(patient.assignment_id), ccauId: this.$getEncodedId(patient.ccauId) } })
        }
      }).catch((e) => {
        this.$handleApiError(e)
      })
    },
    getClinicianCount () {
      this.$store.dispatch('GET_PATIENT', this.activeAssignment.user_id)
    },
    getLink (item) {
      if (!this.clinicianAssignments && item.routeName === 'ClinicianPatientAddMeasure') {
        return {}
      } else {
        return { name: item.routeName, params: item.params }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  a.dropdown-item.inactive {
    color: #B3C1D1 !important;
    cursor: not-allowed;
  }

</style>
